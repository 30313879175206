.storiestitle {
    font-family: 'Bely' !important;
    font-size: 55px;
    color: #000;
    line-height: 55px;
    font-weight: 400;
}

.subtitlestories {
    font-family: 'Bely' !important;
    font-size: 34px;
    line-height: 45px;
    font-weight: 400;
    color: #000;
}

.subheadingofstories {
    font-family: 'Cabinet Grotesk' !important;
    font-weight: 300;
    font-size: 16px;
    color: #000;
}
.subbtitlestories{
    color: white;
}
.subbheadingstories{
    color: white;
}

@media screen and (max-width: 1200px) {
    .changethistorow {
        flex-direction: column-reverse;
    }

    br{
        display: none !important;
    }
}

@media (max-width: 991.98px) {
    .storiestitle {
        font-size: 40px;
        line-height: 47px;
    }
}

@media (max-width: 767.98px) { 
    .subtitlestories{
        font-size:31px
    }
 }

@media (max-width: 575.98px) {
    .storiestitle {
        font-size: 36px;
        line-height: 46px;
        padding: 16px 25px;
    }

    .subtitlestories {
        font-size: 24px;
        line-height: 32px;
    }
}

@media (max-width: 475.98px) {
   

    br {
        display: none;
    }

    .subbtitlestories{
        font-size: 27px;
        line-height: 34px;
    }
    .subbheadingstories{
        font-size: 16px;
        line-height: 28px;
    }

}