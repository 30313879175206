  .buildingsection{
    padding: 0px 200px;
  }

  .titleofagency {
    color: #000;
    font-size: 55px;
    font-family: 'Bely' !important;
    line-height: 75px;
}

.subtitleofagency {
    color: #000;
    font-family: 'Cabinet Grotesk' !important;
    line-height: 30px;
    font-weight: 300;
    font-size: 18px;
}

.buttonofabout {
    color: #000;
    border-radius: 0px;
    border: 1px solid #000;
    outline: none;
    background: transparent;
    padding: 10px 15px;
    font-family: 'Cabinet Grotesk' !important;
    font-weight: 300;
    font-size: 16px;
}

.buttonoftalk {
    border-radius: 0px;
    border: 1px solid #000;
    outline: none;
    background: #000;
    padding: 10px 15px;
    color: white;
    font-family: 'Cabinet Grotesk' !important;
    font-weight: 300;
    font-size: 16px;
}




@media (max-width: 1399.98px) {
    .titleofagency {
        font-size: 55px;
        line-height: 55px;
    }
    .buildingsection{
        padding: 0px 150px;
      }
}

@media (max-width: 1199.98px) {
    .titleofagency {
        font-size: 55px !important;
        line-height: 70px;
    }
    .buildingsection {
        padding: 0px 70px;
    }
}

@media (max-width: 991.98px) {
    .titleofagency {
        font-size: 47px !important;
        line-height: 60px;
    }
    .subtitleofagency {
        color: #000;
        font-family: 'Cabinet Grotesk' !important;
        line-height: 27px;
        font-weight: 300;
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    br{
        display: none;
        }

        .buttonoftalk {
            width: 100%;
        }

        .buttonofabout{
            width: 100%;
        }
}

@media (max-width: 575.98px) {

    .titleofagency {
        line-height: 45px !important;
        font-size: 56px !important;
    }

    .subtitleofagency {
        color: #000;
        font-family: 'Cabinet Grotesk' !important;
        line-height: 26px;
        font-weight: 300;
        font-size: 16px;
    }

    .buttonoftalk {
        width: 100%;
    }

    .buttonofabout {
        width: 100%;
    }
}
