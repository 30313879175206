@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Work+Sans:wght@400;500;600;700&display=swap');




.blackWrapper {
    background-color: #000000;

}
.blackWrapper *::selection {
    background-color: #fff !important;
    color: #000000 !important;
}
footer *::selection {
    background-color: #fff !important;
    color: #000000 !important;
}
.horizontalScroll *::selection {
    background-color: #fff !important;
    color: #000000 !important;
}
.buttonhero {
    background: #A80E01;
    border-radius: 3px;
    color: #FFFFFF;
    border-color: #A80E01;
    padding: 5px 35px !important;
    margin: 5px 35px !important;
    box-shadow: 0px;

}
#testcomponennent {
z-index: 2000;
}

/* .oureteamm {
    padding: 150px 0px;
} */


.titleofcol {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    color: #000000;
}

.subtitleofcol {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #000000;
    padding: 20px 0px;
}


.kolonahero {
    margin: 0px 20px
}

.rreshtihero {
    width: 100% !important;
    justify-content: center;
}



.svgphoto {
    padding: 58px 10px;
}

.slider {
    display: none
}

.slideronmobile {
    display: inherit;
}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 390px) {
    .qoutesslider {
        display: none
    }

    .titleofcol {
        font-size: 15px;
    }

    .subtitleofcol {
        font-size: 10px;
    }

    .mainhero {
        overflow-x: hidden;
    }

    .oureteamm {
        padding: 55px 0px 100px 0px
    }

    .slider {
        padding: 40px 0px;
    }

    .ourworkk {
        padding: 70px 0px;
    }

    .marketting {
        padding: 40px 0px;
    }

    .commentss {
        padding: 40px 0px;
    }

    .qoutesslider {
        padding: 40px 0px;
    }

    .ourfriendss {
        padding: 40px 0px;
    }

}

@media screen and (max-width: 600px) {
    .qoutesslider {
        display: none
    }

    .threevaluesofhero {
        padding: 100px 5px;
    }

    .landingcomponent {
        padding: 0px 0px 0px 0px
    }

    .ourworkmobile {
        padding: 40px 0px 50px 0px;
    }

    .oureteamm {
        padding-bottom: 70px;
    }

    .slideronmobile {
        padding-bottom: 0px;

    }

    .ourworkk {
        padding: 40px 0px;
    }

}

@media screen and (min-width: 600px) {}

@media screen and (min-width: 768px) {
    .oureteamm {
        padding: 70px 0px;
    }

}

@media screen and (min-width: 900px) {}

@media screen and (max-width: 1024px) {

    .sliderondesktop {
        display: none
    }
    .bottompaddingg {
        padding-bottom: 4rem;
      }
    .oureteamm {
        padding: 70px 0px;

    }

    /* .ourworkk {
        display: none;
    } */
}

@media screen and (min-width: 1024px) {

    /* .ourworkmobile {
        display: none;
    } */

    .bottompaddingg {
        padding-bottom: 8rem;
      }
    .titleofcol {
        font-size: 32px;
    }

    .slideronmobile {
        display: none;
    }

    .sliderondesktop {
        display: inherit
    }

    .subtitleofcol {
        font-size: 16px;
    }

    .threevaluesofhero {
        padding: 200px 100px;
    }

    .horizontalscroll {
        height: 500px;
    }


    .qoutesslider {
        display: inherit
    }

    .hiddenbuttonondesktop {
        display: inherit
    }


    .oureteamm {
        padding: 70px 0px;
    }

    .slider {
        padding: 70px 0px;
        display: inherit
    }

    .ourworkk {
        padding: 70px 0px;
    }

    .marketting {
        padding: 70px 0px;
    }

    .commentss {
        padding: 70px 0px;
    }

    .qoutesslider {
        padding: 170px 0px 70px 0px;
    }

    .ourfriendss {
        padding: 70px 0px;
    }
}
 
.updatedcolors {
    color: black !important;
    background-color: white;
}