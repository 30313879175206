.slide1animationphoto {
  animation: 2s ease-in-out 0s 1 normal none running animation1photo;
}

.slide2animationphoto {
  animation: 2s ease 3s 1 normal none running animation2photo;
}

.slide3animationphoto {
  animation: 2s ease-in-out 7s 1 normal none running animation3photo;
}

.landingphoto3 {
  width: 100% !important;
}

.firstlandingtitle {
  font-family: Cabinet Grotesk;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: left;
  color: white;
}
.minuscommand {
  margin-bottom: -80px;
}

.buttonoflanding {
  border: 1px solid #000;
  outline: none;
  background: #fff;
  padding: 6px 15px;
  max-width: 175px !important;
  width: 100%;
  font-family: 'Cabinet Grotesk' !important;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  color: #000;
  border-radius: 0px !important;
}
 
.blackButtonLanding {
  border: 1px solid #fff;
  color: #fff !important;
  background-color: #000 !important;
}
.whiteFormBtn {
  background-color: #000 !important;
  color: #fff !important;

}
.contactBtn {
  max-width: unset !important;
}
.landingtitle {
  font-family: 'Bely' !important;
  font-size: 40px;
  font-weight: 400;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.landingsubtitle {
  font-family: Cabinet Grotesk;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  color: white;
  text-align: left;
}

@keyframes animation1photo {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes animation2photo {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes animation3photo {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.subtitleofslideroflandingpage1 {
  text-align: end;
}

.imageofslideoflandingpage1 {
  text-align: start;
}

.rreshtilandingslide1 {
  display: flex;
}

.rreshtilandingview {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .minuscommand {
    margin-bottom: -80px;
  }
}
@media screen and (max-width: 992px) {
  .minuscommand {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .digitatext {
    font-size: 50px !important;
    line-height: 113.4% !important;
  }
  .subtitleoflandingcomponent {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 20px 40px;
    letter-spacing: -0.02em;
  }

  .landingphoto {
    height: 55vh !important;
  }

  .rreshtilandingslide2 {
    flex-direction: row;
  }
  .rreshtilandingslide1 {
    display: flex;
    height: inherit;
    align-content: flex-end;
    justify-content: flex-end;
  }

  .titleoflandingcomponent {
    font-size: 50px;
    font-weight: 700;
    font-size: 'Bely' !important;
  }

  .majortitullislide1 {
    text-align-last: justify;
    align-self: start;
    text-align: -webkit-left;
  }
  .slide2column2oflandingpage {
    justify-content: flex-end;
  }
  .titleofslideroflandingpage2 {
    text-align-last: right;
  }
}

.subtitleofslideroflandingpage1 {
  text-align: left !important;
  text-align-last: start !important;
}
.subtitleofheader {
  font-weight: 400 !important;
}
@media screen and (max-width: 600px) {
  .majortitullislide2 {
    margin-top: 80px;
  }
  .subtitleofslideroflandingpage1 {
    text-align-last: left;
    text-align: -webkit-left;
  }
  .landingphoto {
    height: 55vh !important;
  }

  .subtitleofslideroflandingpage2 {
    text-align-last: right;
    text-align: -webkit-right;
  }

  .digitalheadingtext {
    font-size: 16px !important;
  }

  .titleofslideroflandingpage2 {
    text-align-last: right;
    text-align: -webkit-right;
  }

  .rreshtilandingslide1 {
    flex-direction: column;
  }

  .rreshtilandingslide2 {
    flex-direction: column;
  }

  .mobilesvglanding {
    margin-top: -110px;
  }

  .titleoflandingcomponent {
    padding-left: 0px;
    font-size: 36px;
    font-weight: 700;
  }
  .titleofslideroflandingpage1 {
    padding-top: 115px;
  }

  .title222 {
    padding-top: 75px;
  }

  .rreshtilandingview {
    display: flex;
    flex-direction: column;
  }

  .titiles {
    margin: 100px 0px 0px 0px;
  }

  .svgmobile {
    display: inherit;
  }

  .svgdesktopofcomponent {
    display: none;
  }

  .heromainbutton {
    background-color: #a80e01 !important;
  }

  .hiddenbuttononmobile {
    display: inherit;
    padding: 80px 0px;
  }

  .hiddenbuttonondesktop {
    display: none;
  }

  .subtitleoflandingcomponent {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 20px 40px;
    letter-spacing: -0.02em;
  }
  .titleofslideroflandingpage1 {
    font-weight: 700;
    font-size: 50px !important;
    line-height: 113.4%;
  }
  .titleoflandingcomponent {
    font-weight: 700;
    font-size: 60px !important;
    line-height: 113.4%;
  }
  .subtitleofheader {
    font-family: Cabinet Grotesk;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: left;
  }
}

@media screen and (max-width: 425px) {
  .subtitleoflandingcomponent {
    padding: 0px 20px;
  }
  .landingphoto {
    height: 50vh !important;
  }

  .rreshtilandingslide1 {
    flex-direction: column;
  }

  .rreshtilandingslide2 {
    flex-direction: column;
  }

  .subtitleofslideroflandingpage1 {
    text-align-last: left;
    text-align: -webkit-left;
  }

  .subtitleofslideroflandingpage2 {
    text-align-last: right;
    text-align: -webkit-right;
  }

  .titleofslideroflandingpage2 {
    text-align-last: right;
    text-align: -webkit-right;
  }

  .titleoflandingcomponent {
    font-weight: 400;
    font-size: 55px !important;
    color: #000000;
  }
  .digitatext {
    font-size: 42px !important;
  }
  .rreshtilandingslide1 {
    align-self: self-end;
  }

  .slide3animationtext {
    text-align-last: start;
  }

  .titleofslideroflandingpage1 {
    font-weight: 700;
    font-size: 50px !important;
    line-height: 113.4%;
  }

  .svgmobileofcomponent img {
    padding-top: -100px;
  }

  .svgmobileofcomponent {
    width: 140px;
  }

  .titiles {
    margin: 100px 0px 0px 0px;
  }
}
@media screen and (max-width: 375px) {
  .rreshtilandingview {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 320px) {
  .titleoflandingcomponent {
    font-size: 20px;
  }

  .titiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 270px;
    padding: 0px 20px;
    overflow-x: hidden;
  }

  .svgmobileofcomponent {
    width: 140px;
  }

  .hiddenbuttononmobile {
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  .subtitleoflandingcomponent {
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) {
  .subtitleofslideroflandingpage2 {
    text-align-last: left;
    text-align: left;
  }
  .majortitullislide1 {
    text-align-last: justify;
    align-self: start;
    text-align: -webkit-right;
  }

  .titiles {
    padding: 10px 0px 100px 0px;
    margin: 100px 0px 0px 0px;
  }

  .landingphoto {
    height: 45vh !important;
  }

  .rreshtilandingslide2 {
    flex-direction: row;
  }
  .majortitullislide1 {
    margin-top: 200px !important;
  }

  .titleoflandingcomponent {
    font-size: 70px;
    font-size: 'Bely' !important;
    font-weight: 700;
  }

  .subtitleoflandingcomponent {
    font-size: 20px;
    font-weight: 400;
    padding: 0px 50px;
  }

  .hiddenbuttonondesktop {
    display: none;
  }

  .photoandsvgtabletandup {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  .photoandsvgtabletandup svg {
    max-width: 800px;
    width: 100%;
    margin: auto;
  }
}

/* @media screen and (min-width: 1024px) {
} */

@media screen and (min-width: 1024px) {
  .subtitleofslideroflandingpage2 {
    text-align-last: left;
  }

  .landingphoto {
    height: 55vh !important;
  }
  .landingphoto2 {
    height: 55vh !important;
  }
  .landingphoto3 {
    height: 30vh !important;
  }

  .majortitullislide1 {
    margin-top: 200px !important;
  }

  .rreshtilandingslide1 {
    flex-direction: row;
  }

  .titiles {
    padding: 10px 0px 100px 0px;
    margin: 100px 0px 0px 0px;
  }

  .rreshtilandingview {
    display: flex;
  }

  .titleoflandingcomponent {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 117px;
    display: flex;
    letter-spacing: -0.02em;
    color: #000000;
  }

  .subtitleoflandingcomponent {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
  }

  .svgmobile {
    display: none;
  }

  .heromainbutton {
    display: inherit;
  }

  .hiddenbuttononmobile button {
    padding: 15px 35px;
  }

  .rreshtilandingview {
    display: flex;
    flex-direction: column;
  }
}
.titleoflandingcomponent {
  font-family: 'Bely' !important;
  font-weight: 300;
  line-height: 100%;
}
@media screen and (min-width: 1424px) {
  .titiles {
    padding: 10px 0px 100px 0px;
    margin: 100px 0px 0px 0px;
  }

  .titleoflandingcomponent {
    font-size: 80px;
    line-height: 95px;
  }

  .landingphoto {
    height: 65vh !important;
  }

  .majortitullislide1 {
    margin-top: 200px !important;
  }
}

@media screen and (min-width: 1524px) {
  .titiles {
    padding: 40px 0px 100px 0px !important;
    margin: 50px 0px 0px 0px !important;
  }

  .majortitullislide1 {
    margin-top: 200px !important;
  }
}

@media screen and (min-width: 2000px) {
  .titiles {
    padding: 70px 0px 100px 0px !important;
    margin: 80px 0px 0px 0px !important;
  }

  .rreshtilandingslide1 {
    width: 100%;
  }

  .rreshtilandingslide1 {
    flex-direction: row;
  }
}

.heromainbutton {
  margin: 60px 0px 0px 0px;
  background-color: #a80e01 !important;
}

.digitatext {
  font-family: 'Bely' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 63px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  color: #000000;
}

.digitalheadingtext {
  font-family: 'Cabinet Grotesk' !important;
  font-style: normal;
  font-size: 18px;
  line-height: 160.4%;
  color: #000000;
  font-weight: 300;
}

@media (min-width: 1400px) {
  .imgdigital {
    width: 500px;
    height: 375px !important;
  }
}

::selection {
  background-color: black;
  color: white;
}

.containerofspace {
  padding-top: 8rem;
}

@media (max-width: 768px) {
  .containerofspace {
    padding-top: 8rem;
  }

}

@media (max-width: 601.98px) {
  .containerofspace {
    padding-top: 3rem;
  }
  .navbarGap {
    margin-top: 10vh;
  }
}
