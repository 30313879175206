
.borderofhire {
    border-radius: 52px;
    border: 1px solid;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.maintitleofhireus {
    font-size: 130px;
    font-family: 'Bely' !important;
    line-height: 110px;
    color: #000;   
}
@media screen and (min-width: 990px){
    .maintitleofhireus {
        font-size: 130px;
    }    
    .buttonofhireus {
        width: 50%;
    }
    .buttonofhireus {
        font-size: 46px;
    }
}
@media screen and (max-width: 990px){
    .maintitleofhireus {
        font-size: 100px;
    }    
    .buttonofhireus {
        font-size: 36px;
    }
}
@media screen and (max-width: 790px){
    .maintitleofhireus {
        font-size: 80px;
    }    
    .buttonofhireus {
        font-size: 36px;
    }
    
}
@media screen and (max-width: 590px){
    .maintitleofhireus {
        font-size: 50px;
       line-height: 70px;   

    }    
    .buttonofhireus {
        font-size: 26px;
    }
    .borderofhire {
        height: 50vh;
    }    
}

.buttonofhireus {
    border-radius: 52px;
    background-color: transparent;
    color: #000;
}