.titleofchoose {
    font-family: 'Bely' !important;
    font-size: 38px;
    line-height: 58px;
    color: #000;
}

.subtitleofchoose {
    font-family: 'Bely' !important;
    font-size: 36px;
    line-height: 45px;
    background-color: black;
    color: white;
    padding: 0px 8px !important;
}

.titleofwho {
    font-family: 'Bely' !important;
    font-size: 24px;
    line-height: 24px;
}

.subtitleofwho {
    font-family: 'Cabinet Grotesk' !important;
    font-weight: 300;
    font-size: 18px;
}

.boxx {
    background-color: #F5F5F5;
    border-radius: 0px;
    padding: 20px 24px;
}


.boxx:hover {
    background-color: #000000;
    color: white;
}

.whysubtitleheading {
    width: 80%;
}

@media (max-width: 1399.98px) {
    .whysubtitleheading {
        width: 77%;
    }
}

@media (max-width: 1199.98px) {
    .whysubtitleheading {
        width: 93%;
    }
}

@media (max-width: 991.98px) {
    .whysubtitleheading {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .whyflex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subttileheading {
        text-align: center !important;
        display: flex !important;
        align-items: center !important;
    }
    .titleofchoose{
        padding: 0px 23px !important;
        font-size: 36px !important;
        line-height: 40px !important;
    }
    .subtitleofchoose{
        font-size: 36px !important;
    }
}

@media (max-width: 575.98px) {
    .titleofwho {
        line-height: 24px;
        font-size: 24px;
    }

    .subtitleofwho {
        font-size: 16px;
        line-height: 26px;
    }
    .titleofchoose{
        padding: 0px 13px !important;
        font-size: 43px !important;
        line-height: 60px !important;
    }
    .subtitleofchoose{
        font-size: 40px !important;
    }
}

