.containercard {
    display: flex;

}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

 
.galleryofportfolio .cardofdata {
    padding-bottom: 1rem;
}
.galleryofportfolio .cardofdata:nth-child(7) {
    padding-bottom: 0rem;
}
 
 


.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: rgba(59, 42, 40, 0.6);

}

.containerofphoto {
    position: relative;
}

.containerofphoto:hover .overlay {
    opacity: 1;
    cursor: pointer
}


.texttt {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}


.hovereffectofphoto:hover img {
    z-index: 9999;
}

.centered {
    display: none;
}

@media (min-width: 1400px) {

    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl .containeroftabs {
        max-width: 1620px;
    }
}