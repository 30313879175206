@media screen and (min-width: 1200px){
  .grid-container {
    display: grid;
    grid-template-columns: repeat(
      5,
      1fr
    ); /* This creates 5 columns of equal size */
    gap: 10px;
    padding: 10px;
  }
  .grid-container > div {
    width: 100%; /* Divide by 5 columns, subtract gap and border */
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1200px){
  .grid-container {
    display: grid;
    grid-template-columns: repeat(
      3,
      1fr
    ); /* This creates 5 columns of equal size */
    gap: 10px;
    padding: 10px;
  }
  .grid-container > div {
    width: 100%; /* Divide by 5 columns, subtract gap and border */
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 800px){
  .grid-container {
    display: grid;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* This creates 5 columns of equal size */
    gap: 10px;
    padding: 10px;
  }
}
@media screen and (max-width: 500px){
  .grid-container > div {
    width: 100%; /* Divide by 5 columns, subtract gap and border */
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    text-align: center;
    font-size: 30px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-item img {
    width: 100px;
  }
}


.titleofgridd {
  font-family: 'Bely';
  font-size: 40px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.9599999785423279px;
  text-align: left;
}
