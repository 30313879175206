.servicebg {
    display: flex;
    border-radius: 0px;
    flex-direction: column;
    /* width: 40vh; */
    /* height: 40vh; */
    justify-content: center;
}

.greentile {
    background-color: #14AE5C;
    width: 100px;
    height: 30px;
    border-radius: 3px;
}

.titleofcontact {
    font-weight: 700;
    font-size: 45px;
    line-height: 53px;
    color: #1F1F1F;
}

.buttonofcontact {
    background: #A80E01 !important;
    border-radius: 0px;
    border: none !important;
}
.buttonofcontact:hover {
    color: white !important
}
.subtitleofcontact {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #1F1F1F;

}
.servicestoptitle {
    font-family: 'Bely' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 130.4%;
    letter-spacing: -0.02em;
    color: #000000;
    
}

.subheadingtitle{
    font-family: 'Cabinet Grotesk' !important;
    font-style: normal;
    font-size: 18px;
    line-height: 160.4%;
    color: #000000;
    font-weight:300;
}

.subservicestoptitle {
    font-family: 'Cabinet Grotesk' !important;
    font-style: normal;
    font-size: 18px;
    line-height: 130.4%;
    letter-spacing: -0.02em;
    color: #000000;
    font-weight:300;
}
.okfedls {
    border-radius: 0px;
}

.rowofourservicess {
    display: flex;
    flex-wrap: nowrap;
}



.titleofboxservices {
    font-weight: 700;
    line-height: 33px;
    /* identical to box height */


    color: #FFFFFF;
}

.servicesstylee {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitleofboxservices {
    font-weight: 400;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.01em;

    color: #FFFFFF;
}

.servicessimagess {
    max-height: 650px !important;
}
.titleservice {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;

}

.boxofservices {
    display: flex;
    justify-content: center;
}


@media (min-width: 2560.92px) {
    .imageoftopservicesas {
        text-align: left;
    }
    .subtitleofboxservices {
        font-size: 16px;
    }
    .titleofboxservices {
        font-size: 28px;
    }
    
    

    .imageoftopservices {
        font-size: 89px,
            /* text-align: start,
        fontWeight: '800' */
    }

    .titleoftopservices {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 800;
        font-size: 70px;
        line-height: 82px;
        text-align: start;
        letter-spacing: -0.02em;
    }
    .boxofservice {
        height: 200px
    }
    .servicesstylee {
        width: 453px;
        height: 436px;
    
    }
}
@media (max-width: 2560.92px) {

    .thisshowsinmobile {
        display: none
    }
    .imageoftopservicesas {
        text-align: left;
    }
    .subtitleofboxservices {
        font-size: 16px;
    }
    .titleofboxservices {
        font-size: 28px;
    }
    
    

    .imageoftopservices {
        font-size: 89px,
            /* text-align: start,
        fontWeight: '800' */
    }

    .titleoftopservices {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 800;
        font-size: 70px;
        line-height: 82px;
        text-align: start;
        letter-spacing: -0.02em;
    }
    .boxofservice {
        height: 200px
    }
    .servicesstylee {
        width: 453px;
        height: 436px;
    
    }

}
@media (max-width: 1940px) {
    .boxofservice {
        height: 200px
    }

    .imageoftopservicesas {
        text-align: left;
    }

    .imageoftopservices {
        font-size: 89px,
            /* text-align: start,
        fontWeight: '800' */
    }

    .titleservice {
        font-size: 36px;
    }

    .titleoftopservices {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 800;
        font-size: 70px;
        line-height: 82px;
        text-align: start;
        letter-spacing: -0.02em;
    }


    .thisshowsindesktop {
        display: inherit;
    }

    .servicesstylee {
        width: 453px;
        height: 436px;
    
    }

}

/* @media (min-width: 992px    ) {
    .colofourservicess {
        flex: 0 0 auto;
        width: 33.333% !important;
    }

} */

@media (max-width: 1440px) {
    .titleservice {
        font-size: 20px;
    }

    .servicesstylee {
        align-items: center;
        height: 337px;
        width: 337px;
        
    }

    .rowofourservicess {
        justify-content: center;
    }

    .colofourservicess {
        justify-content: center;

    }

    .titleoftopservices {
        font-style: normal;
        font-weight: 800;
        font-size: 60px;
        line-height: 72px;
        text-align: start;
        letter-spacing: -0.02em;
    }
}

@media (max-width: 1240px) {
    .servicesstylee {
        align-items: center;
        height: 100%;
        width: 287px;
        
    }

}
@media (max-width: 1199.98px) { 
    .servicestoptitle{
        font-size: 38px !important;
    }
 }

@media (max-width: 1024px) {
    .thisshowsindesktop {
        display: none
    }
    .thisshowsinmobile {
        display: inherit;
    }

}

@media (max-width: 992px) {
    .colofourservicess  {
        display: flex;
    }
    .servicestoptitle{
        font-size: 48px !important;
        line-height: 125.4% !important;
    }
}

@media (max-width: 768px) {}

@media (max-width: 600px) {
    /* .rowofourservicess {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */

    .subtitleofboxservices {
        font-size: 14px;
    }
    .titleofboxservices {
        font-size: 18px;

    }
    .boxofservice {
        height: 150px;
    }

  
  

    .imageoftopservicesas {
        text-align: right;
    }

    .servicesstylee {
        width: 297px;
        height: 200px;
    }

    .imageofboxes {
        width: 75px;
        height: 650px !important;
    }

    .titleservice {
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;

    }

    .titleoftopservices {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        letter-spacing: -0.02em;
        padding: 0px 20px;
    }

    .rowoftopservices {
        display: flex;
        flex-direction: column;
    }

    .imageoftopservices {
        margin-top: -70px;
        margin-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .servicestoptitle{
        font-size: 34px !important;
        line-height: 125.4% !important;
    }
    .imagecss {
        height: 7vh;
    }

    .imagecss2 {
        height: 10vh;
    }

    .imagecss3 {
        height: 11vh;
    }

    .imagecss4 {
        height: 10vh;
    }
    .subheadingtitle{
        font-size: 16px;
    }
    .servicestoptitle{
        font-size: 40px;
    }
}

.socialmedia{
    width: 75%;
}

.socialcontainer{
    width: 80%;
}

@media (max-width: 991.98px) { 
    .socialcontainer{
        width: 100%;
    }
 }

 @media (min-width: 1199.98px) { 
    .socialheadingstyle{
        width: 98%;
    }
  }

  
  .containerofspace{
    padding-top: 8rem;
}


@media (max-width: 768px) { 
    .containerofspace{
        padding-top: 6rem;
    }
 }

@media (max-width: 601.98px) { 
    .containerofspace{
        padding-top: 3rem;
    }
 }