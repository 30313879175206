body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');


/* font-family: 'Cabinet Grotesk', sans-serif; */
                                                
#tsparticles {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 600px) {
.titleofups {
  font-size: 55px !important;
}
.subtitleofups {
  font-size: 30px !important;
}
}

@media screen and (min-width: 2100px) {

  .onverybigscreens {
    margin-top: 10rem;
  }
  
}