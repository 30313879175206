.positioncenter .hovereffectonthiscomponent:nth-child(1) .borderoncomponent {
  border-top: none;
}
.positioncenter .hovereffectonthiscomponent:nth-child(2) .borderoncomponent {
  border-top: none;
}

.borderoncomponent {
  border-top: 1px solid grey;
  padding-right: 3rem;
  padding-left: 3rem;
}

@media screen and (max-width: 1400px) {
  .borderoncomponent {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media screen and (max-width: 1400px) {
  .positioncenter .hovereffectonthiscomponent:nth-child(2) .borderoncomponent {
    border-top: 1px solid grey;
  }
  
}


/* Default Styles */

/* .displaythisonhover {
  opacity: 0;                  
  visibility: hidden;          
  transform: translateX(-40%);    
  transition: 
      opacity 0.3s ease-in-out, 
      transform 0.3s ease-in-out, 
      visibility 0.3s ease-in-out;
}

.borderoncomponent:hover .row .displaythisonhover {
  opacity: 1;                  
  visibility: visible;         
  transform: translateX(-20%); 
} */



.image2 {
  display: none;
  height: 190px;
}
.image {
  height: 190px;
}

.positioncenter {
  justify-content: center !important;
}

.component1 {
  display: none;
}
.titleofthecapabilities {
  font-family: Bely !important;
  font-size: 41px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: -0.02em;
  text-align: left;
}

.titlecapabilities {
  font-family: "Bely" !important;
  font-size: 50px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: -0.02em;
  text-align: left;
}


@media screen and (min-width: 700px){
  .onlyondesktop {
    display: inherit;
  }
  .onlyonmobile {
    display: none;
  }
  .hovereffectonthiscomponent:hover {
    background-color: black;
    color: white;
  }
  .borderoncomponent:hover .row {
    flex-direction: row-reverse;
    transition: 0.5s;
    justify-content: flex-end;
  }
  
  
  .borderoncomponent:hover .row .image2 {
    display: inherit;
    transition: 0.5s;
  }
  .component1 {
    margin-top: auto;
  }
  
  .hovereffectonthiscomponent:hover .component1 {
    display: inherit;
  }
  .borderoncomponent:hover .row .image {
    display: none;
  }
  .borderoncomponent:hover .row .removethisonhover {
    display: none;
  
  }
  .removethisonhover {
    color: black
  }
 }

@media screen and (max-width: 700px){
  .onlyondesktop {
    display: none;
  }
  .onlyonmobile {
    display: inherit;
  }
  .titelfefe {
    text-align: center;
  }
  .titleofthecapabilities {
    font-size: 30px;
  }
  .image2 {
    margin-left: 6rem;
    max-width: auto;
    height: 100%;
    margin-left: -0px;
  }
  .titelfefe {
    display: none;
  }

   .image {
    height: 150px;
    width: 170px;
   }
   .titleofthecapabilities {
    font-size: 25px;
    padding-left: 1rem;
   }
   .borderoncomponent {
    padding-left:  1rem;
    padding-right: 1rem;
   }
   .component1 {
    display: none;
   }
}



/* @media screen and (min-width: 768px) {
  .component1 {
    text-align: left;
  }
  .titleofthecapabilities {
    text-align: center;
  }
  .borderoncomponent:hover .row  .selectorrrr .titleofthecapabilities {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .titleofthecapabilities {
    text-align: center;
  }
  .borderoncomponent:hover .row  .selectorrrr .titleofthecapabilities {
    text-align: center;
  }
  .image2 {
    width: 100%;
  }
  .component1 {
    text-align: center;
  }
} */
