.numberofinc {
    font-size: 69px !important;
    color: white;
    font-family: Cabinet Grotesk !important;
}


.titleofnumberss {
    color: white;
    font-size: 25px;
    font-family: Cabinet Grotesk !important;

}


@media screen and (max-width: 992px) {
    .lineonsmallscreens {
        border-bottom: 1px solid white;
    }
}