@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Work+Sans:wght@400;500;600;700&display=swap');

body {
    position: relative;
}



.noNavBg {
    background-color: transparent;
}

.inverse-color-header {
    display: flex;
    flex-direction: column-reverse;
}

.inverse-colors {
    color: white;
}

.navBgcontact svg path {
    fill: #fff;
}

.navBgcontact svg path {
    stroke: #fff;
}

.navBg {
    background: #fff;
    margin: 10px 0px;
}


.contactNavTransition {
    transition-delay: 0s !important;
}
.navBgcontact {
    background-color: #000;
    transition-delay: 2s !important;
    
}

.buttonscrolleffect {
    color: white !important;
    background-color: #000 !important
}

.navlinksondesktop:active #linksss {
    color: #A80E01
}

.buttunstyle {
    font-family: Bely;
    font-weight: 400;
    font-size: 16px;
    position: absolute;

}

.nav-container {
    position: fixed;
    top: 0;
    width: 100%;
    transition: background-color 0.2s ease-in-out;
    padding-left: 10rem;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 10rem;
}

.navLinks {
    color: black;
}

.navLinks:hover {
    color: #9024b4;
}

body.lock-position {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}

.challengeUsBtn {
    border: 1px solid #9024b4;
    border-radius: 0px;
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(90deg, #b35bcf, #9024b4);
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size .5s, color .5s;
}

.challengeUsBtn:hover {
    color: #fff;
    background-size: 100% 100%;
}
.servicesdetailpage {
    transition-delay: 0s !important;

}
#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
    position: relative;
    width: 19px;
    vertical-align: -webkit-baseline-middle;
    height: 25px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    outline: none;
    outline: 0;
    border: none !important;
    background-color: transparent;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    border: none !important;
    outline: none;
}

#nav-icon1 span:nth-child(1) {
    top: 13px;
}

#nav-icon1 span:nth-child(2) {
    top: 6px;
}

#nav-icon1 span:nth-child(3) {
    top: 20px;
}

#nav-icon1.open span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media only screen and (min-width: 1024px) {
    .nav-scrolled {
        display: none;
        transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    }
}

a {
    text-decoration: none !important;
}


@media (min-width: 2570px) {
    .mobilelogohidden {
        display: none
    }

    #mobilelogohidden {
        display: none
    }

    .nav-scrolled {
        display: none;
        transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    }

    .navTabs .navTabsRow {
        display: none;
        background-color: inherit
    }

    .navlinksondesktop {
        display: flex
    }

    .navlinksondesktop {
        vertical-align: -webkit-baseline-middle;

    }

    .navbar-nav {
        display: flex;
    }

    #linksss:hover~#linksss {
        color: #3d3d3d;
    }

    .navlinksondesktop:hover #linksss {
        color: #3d3d3d;
    }

    .navlinksondesktop #linksss:hover,
    .navlinksondesktop #linksss:hover~a {
        color: #000;
    }

    .navlinksondesktop {
        display: inline;
        padding: 0px 50px;
        -webkit-box-align: baseline;
        align-self: center;
    }

    #navbarnav {
        flex-direction: row;
        padding: 20px 0px;
        margin: 20px 0px;
    }

    .navlinksondesktop #linksss {
        padding: 20px 20px;
        color: #000;
        font-weight: 600;
        font-size: 16px;
    }


    .dropbtn {
        font-size: 16px;
        border: none;
    }

    .dropdown-content {
        display: none;
        left: 0;
        position: absolute;
        background-color: #f1f1f1;
        width: 526px;
        margin-top: 15px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
        z-index: 1;
        background: #FFFFFF;
        border: 1px solid rgba(168, 14, 1, 0.08);
        box-shadow: 0px 4px 4px rgb(235 228 228 / 25%);
        border-radius: 13px;
    }

    .rreshtiidroppdown {
        align-items: center;
        justify-content: center;
    }

    .titleofdropdownitem {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: -0.02em;

        color: #2E2E32;

    }

    .photoofdropdown {
        text-align-last: center;
    }

    .subtitleofdropdownitem {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;

        color: #2E2E32;

    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        background: rgba(168, 14, 1, 0.06);
        border-radius: 7px;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }



    .nav-container {
        margin: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap !important;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .headerwrapper {
        display: flex
    }

    .navvlinkk {
        padding: 0px 20px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;

        color: #A80E01;
    }

    .navvlinkka {
        color: #A80E01 !important;

    }

    .mobilelogohidden {
        background-color: aquamarine;
    }

    .mobilelogo {
        display: none;
    }
}

@media (max-width: 2570px) {
    .mobilelogohidden {
        display: none
    }

    #mobilelogohidden {
        display: none
    }

    .nav-scrolled {
        display: none;
        transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    }

    .navTabs .navTabsRow {
        display: none;
        background-color: inherit
    }

    .navlinksondesktop {
        display: flex
    }

    .navlinksondesktop {
        vertical-align: -webkit-baseline-middle;

    }

    .navbar-nav {
        display: flex;
    }

    #linksss:hover~#linksss {
        color: #757373;
    }

    .navlinksondesktop:hover #linksss {
        color: #757373;
    }

    .navlinksondesktop #linksss:hover,
    .navlinksondesktop #linksss:hover~a {
        color: #000;
    }

    .navlinksondesktop {
        display: inline;
        padding: 0px 50px;
        -webkit-box-align: baseline;
        align-self: center;
    }

    #navbarnav {
        flex-direction: row;
        padding: 20px 0px;
        margin: 20px 0px;
    }

    .navlinksondesktop #linksss {
        padding: 20px 20px;
        color: #000;
        font-weight: 600;
        font-size: 16px;
    }


    .dropbtn {
        font-size: 16px;
        border: none;
    }

    .dropdown-content {
        display: none;
        left: 0;
        position: absolute;
        background-color: #f1f1f1;
        width: 526px;
        margin-top: 15px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
        z-index: 1;
        background: #FFFFFF;
        border: 1px solid rgba(168, 14, 1, 0.08);
        box-shadow: 0px 4px 4px rgb(235 228 228 / 25%);
        border-radius: 13px;
    }

    .rreshtiidroppdown {
        align-items: center;
        justify-content: center;
    }

    .titleofdropdownitem {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: -0.02em;

        color: #2E2E32;

    }

    .photoofdropdown {
        text-align-last: center;
    }

    .subtitleofdropdownitem {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.02em;

        color: #2E2E32;

    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        background: rgba(168, 14, 1, 0.06);
        border-radius: 7px;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }



    .nav-container {
        margin: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap !important;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .headerwrapper {
        display: flex
    }

    .navvlinkk {
        padding: 0px 20px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;

        color: #A80E01;
    }

    .navvlinkka {
        color: #A80E01 !important;

    }

    .mobilelogohidden {
        background-color: aquamarine;
    }

    .mobilelogo {
        display: none;
    }
}

#buttonofnavbar {

    border: 2px solid #000;
    color: #000;
    font-weight: 700;
    padding: 6px 19px;
}

#buttonofnavbar:hover {
    border: 2px solid #000;
    background-color: #000;
    color: white;
}

#buttonofnavbar:focus {
    box-shadow: unset;
}

.apgjprkgpoa {
    position: relative;
}



@media (max-width: 1440.98px) {
    a {
        margin-top: 5px;
    }

    .navbarlogoo {
        margin-top: 5px;

    }
}

@media (max-width: 1126px) {
    .navLogo {
        display: flex;
    }

}

@media (max-width: 1099.98px) {
    .titleofcircless {
        font-size: 23px;
        line-height: 36px;
        font-weight: 700;
    }



    #buttonofnavbar {
        padding: 12px 14px;
    }

    .nav-container {
        padding: 0px;
        padding: 15px 30px;
    }

    .burgerCol {
        padding-top: 10px;
    }

    .buttonofmenutabs {
        display: flex;
        width: -webkit-fill-available;
        align-content: flex-end;
        align-items: flex-end;
        background-color: white;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        justify-items: flex-end;
        margin: 0px;
    }

    #nav-icon1 {
        width: 21px;
    }

    .subtitlesofservices {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #2E2E32;
    }

    .rreshtiofmobiletabs {
        justify-content: center;
        align-items: center;
        padding: 5px 40px 5px 40px;

    }

    .buttonofmenutabs span {
        background-color: black
    }

    .buttonofmenutabs img {
        margin-right: 10px;
    }

    .kolonaofmobiletabs {
        text-align: start;

    }

    .photoplaceholder {
        padding: 20px;
    }

    .titleofcircles {
        display: flex;
        font-size: 23px;
        padding: 0px 45px 0px 45px;
        font-weight: 700;

    }

    .photoplaceholder {
        padding: 15px 27px;
    }

    .navLogo {
        display: flex;
        z-index: 9999;

    }

    /* .nav-scrolled {
        display: none;
        transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    } */
    .nav-container {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .mobilelogohidden {
        display: none;
    }


    .navTabs {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0rem;
        text-align: center;
        transition: all 0.4s ease-in-out;
        left: 1500px;
        animation: none;
    }

    .navTabsOpen {
        left: 0px;
        transition: all 0.4s ease-in-out;
        scroll-behavior: none;
    }

    .navTabs .navTabsRow {
        display: block !important;
        width: 110vw;
        padding-top: 8rem;
        height: 150vh;
        transition: all 1s ease-in-out;
        text-align: center;
    }

    .navLinks {
        padding-right: 0px !important;
        font-size: 1.5rem;
    }

    .challengeUsBtn {
        font-size: 1.5rem;
    }

    .navLinks .d-flex {
        justify-content: center;
    }

    .buttonofmenutabs {
        position: absolute;
        display: flex;
        width: -webkit-fill-available;
        align-content: center;
        align-items: center;
        margin: 0px 110px 0px 30px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

    }

}

@media (min-width: 991px) {

    #buttonofnavbar2 {
        display: inherit;
        float: right;
        border: 2px solid #000;
        color: #000;
        font-weight: 700;
        padding: 6px 19px;
    }
}

@media (max-width: 991px) {
    .navlinksondesktop {
        display: none;
    }

    #buttonofnavbar {
        display: inherit
    }

    #buttonofnavbar2 {
        display: none
    }

    /* .nav-scrolled {
        display: inherit;
        transition-timing-function: cubic-bezier(.29, 1.01, 1, -0.68);
    } */
    .nav-container {
        padding-left: 1.5rem;
        padding-right: 1.2rem;
    }



    #navbarnav {
        display: none;
    }

    .navTabs .navTabsRow {
        background-color: white;
    }

    .navTabs {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0rem;
        text-align: center;
        transition: all 0.4s ease-in-out;
        left: 1000px;
        animation: none;
    }

    .navTabsOpen {
        left: 0px;
        transition: all 0.4s ease-in-out;
    }

    .navTabs .navTabsRow {
        display: block !important;
        background-color: white;
        width: 110vw;
        padding-top: 5rem;
        height: 150vh;
        transition: all 1s ease-in-out;
        text-align: center;
    }

    .navLinks {
        padding-right: 0px !important;
        font-size: 1.5rem;
    }

    .challengeUsBtn {
        font-size: 1.5rem;
    }

    .navLinks .d-flex {
        justify-content: center;
    }

    .buttonofmenutabs {
        position: absolute;
        width: -webkit-fill-available;
        align-content: center;
        align-items: center;
        flex-direction: row;
        margin: 0px 30px;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 20px 90px 20px 20px;
    }
}

@media (max-width: 834.98px) {}

@media (min-width: 834.98px) {
    #buttonofnavbar {
        display: none
    }


    #buttonofnavbar2:hover {
        background-color: #000;
        color: white !important
    }

    #buttonofnavbar2:focus {
        box-shadow: none;
    }

}


.titleoflandingcomponent {
    color: #000;
}

@media (max-width: 767.98px) {
    .titiles {
        margin: 100px 0px 0px 0px;
    }

    .titleoflandingcomponent {
        font-size: 50px;
        font-weight: 400;
        line-height: 112%;
    }

    .nav-container {
        padding-left: 1.5rem;
        padding-right: 1.2rem;
    }

    .navTabs {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0rem;
        text-align: center;
        transition: all 0.4s ease-in-out;
        left: 1000px;
    }

    .navTabsOpen {
        left: 0px;
        transition: all 0.4s ease-in-out;
    }

    .navTabs .navTabsRow {
        display: block !important;
        background-color: white;
        width: 110vw;
        padding-top: 5rem;
        height: 150vh;
        transition: all 1s ease-in-out;
        text-align: center;
    }

    .navLinks .d-flex {
        justify-content: center;
    }

    .navLinks {
        padding-right: 0px !important;
        font-size: 1.5rem;
    }

    .challengeUsBtn {
        font-size: 1.5rem;
    }

    .buttonofmenutabs {
        position: absolute;
        display: flex;
        width: -webkit-fill-available;
        align-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 20px 90px 20px 20px;

    }

    .subtitle {
        font-family: 'Raleway' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* text-align: center; */
        letter-spacing: -0.02em;
        color: #2E2E32;
    }

    .title {
        font-family: 'Raleway' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 35px;
        line-height: 76px;
        text-align: center;
        letter-spacing: 0.07em;
        color: #2E2E32;
    }
}

@media (max-width: 575.98px) {
    #nav-icon1 {
        width: 21px;
    }

    #buttonofnavbar {

        padding: 6px 11px;
        margin-top: 3px;
        border-radius: 3px;

    }

    .navLogo {
        z-index: 9999;
        text-align: start;
        align-self: flex-end;
    }

    .buttonofmenutabs {
        position: absolute;
        display: flex;
        width: -webkit-fill-available;
        align-content: center;
        align-items: center;
        background-color: white;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: 11px 60px 20px 20px;

    }

    .subtitlesofservices {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #2E2E32;
    }

    .rreshtiofmobiletabs {
        justify-content: center;
        align-items: center;
        padding: 5px 40px 5px 20px;

    }

    .buttonofmenutabs span {
        background-color: black
    }

    .titleofcircless {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;

    }

    .buttonofmenutabs img {
        margin-right: 10px;
    }

    .kolonaofmobiletabs {
        text-align: start;

    }

    .photoplaceholder {
        padding: 20px;
    }


    .buttonofmenutabs .colorofopenburger {
        background-color: black;

    }

    .titleofcircles {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;
    }

    .simplefeatures {
        font-size: 14px;
    }

    .simplefeatures:hover {
        background: rgba(168, 14, 1, 0.06);
        border-radius: 17px;

    }

    .simplefeatures {
        display: flex;
        align-content: center;
        align-items: center;
        padding: 0px;
        flex-direction: row;
        /* margin: 20px 70px 24px 56px; */
        margin: 20px 0px;
        padding: 0px;
        font-size: 18px;

    }

    .hovereffect {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 10px 0px !important;
        margin: 0px !important;
    }

    .hovereffect:hover {
        background: rgba(168, 14, 1, 0.06);
        border-radius: 17px;
    }

    .photoplaceholder {
        border-style: solid;
        padding: 20px 30px;
        border-radius: 50px;
    }

    .nav-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .navLinks {
        width: 100%;
    }

    .navTabs {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0rem;
        text-align: center;
        transition: all 0.4s ease-in-out;
        left: 1000px;
    }

    .navTabsOpen {
        left: 0px;
        transition: all 0.4s ease-in-out;
    }

    .navTabs .navTabsRow {
        display: block !important;
        background-color: white;
        width: 110vw;
        padding-top: 5.3rem;
        height: 100vh;
        transition: all 1s ease-in-out;
        text-align: center;
        overflow-y: auto;
    }

    .navLinks {
        padding-right: 0px !important;
        font-size: 1.5rem;
    }

    .challengeUsBtn {
        font-size: 1.5rem;
    }

    .navLinks .d-flex {
        justify-content: center;
    }

    .titleofcircless {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;
    }
}

@media screen and (max-width: 425.98px) {
    .titleofcircless {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;
    }

    .navLogo {
        z-index: 9999;
        text-align: start;
        align-self: flex-end;
    }

    #buttonofnavbar {
        padding: 6px 11px;
        margin-top: 7px;
        border-radius: 3px;
    }

    .nav-container {
        padding: 0px;
        padding: 15px 30px;
    }

    .burgerCol {
        padding-top: 10px;
    }

    .buttonofmenutabs {
        position: absolute;
        display: flex;
        width: -webkit-fill-available;
        align-content: flex-end;
        align-items: flex-end;
        background-color: white;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        justify-items: flex-end;
        padding: 6px 85px 20px 64px;
        margin: 0px;
    }

    #nav-icon1 {
        width: 21px;
    }

    .subtitlesofservices {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #2E2E32;
    }

    .rreshtiofmobiletabs {
        justify-content: center;
        align-items: center;
        padding: 5px 40px 5px 20px;

    }

    .buttonofmenutabs span {
        background-color: black
    }

    .buttonofmenutabs img {
        margin-right: 10px;
    }

    .kolonaofmobiletabs {
        text-align: start;

    }

    .photoplaceholder {
        padding: 20px;
    }

    .titleofcircles {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;
    }

    .photoplaceholder {
        padding: 15px 27px;
    }
}

@media (max-width: 390.98px) {
    .titleofcircless {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;
    }

    .navLogo {
        z-index: 9999;
        text-align: start;
        align-self: flex-end;
    }

    #buttonofnavbar {
        padding: 6px 11px;
        margin-top: 10px;
        border-radius: 3px;
    }

    .nav-container {
        padding: 0px;
        padding: 15px 30px;
    }

    .burgerCol {
        padding-top: 10px;
    }

    .buttonofmenutabs {
        position: absolute;
        display: flex;
        width: -webkit-fill-available;
        align-content: flex-end;
        align-items: flex-end;
        background-color: white;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        justify-items: flex-end;
        padding: 6px 85px 20px 64px;
        margin: 0px;
    }

    #nav-icon1 {
        width: 21px;
    }

    .subtitlesofservices {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #2E2E32;
    }

    .rreshtiofmobiletabs {
        justify-content: center;
        align-items: center;
        padding: 5px 40px 5px 20px;

    }

    .buttonofmenutabs span {
        background-color: black
    }

    .buttonofmenutabs img {
        margin-right: 10px;
    }

    .kolonaofmobiletabs {
        text-align: start;

    }

    .photoplaceholder {
        padding: 20px;
    }

    .titleofcircles {
        display: flex;
        font-size: 18px;
        padding: 0px 45px 0px 30px;
        font-weight: 700;

    }

    .photoplaceholder {
        padding: 15px 27px;
    }
}

@media screen and (max-width: 320px) {
    .nav-container {
        padding: 15px 60px 15px 25px;
    }

}

@media screen and (max-width: 375px) {
    .nav-container {
        padding: 20px 40px 15px 20px;
    }

}

@media screen and (min-width: 2000px) {
    .nav-container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #linksss {
        font-size: 18px !important;
    }

    #linksss .dropbtn {
        font-size: 18px !important;
    }

}