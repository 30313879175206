.form-control:focus {
    color: #ffffff !important;
    background-color: #fff;
    border-color: #4f4f4f !important;
    outline: 0;
    box-shadow: none !important;
}

.form-select:focus {
    border-color: #4f4f4f !important;
    outline: 0;
    box-shadow: none !important;
    color: #ffffff !important;
}

.contacttitle {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    color: #000;
    font-family: 'Bely' !important;
    padding: 0px 90px;
}

.contactheadingtitle {
    font-weight: 700;
    font-size: 28px;
    color: #000;
    font-family: 'Bely' !important;
    padding: 0px 90px;
}

.contactsubtitle {
    font-size: 18px;
    font-family: 'Cabinet Grotesk' !important;
    font-weight: 300;
    line-height: 30px;
    color: #000;
    padding: 15px 90px;
}

.contactBlackClass .contacttitle {
    color: #fff !important;
}

.contactBlackClass .contactheadingtitle {
    color: #fff !important;
}

.contactBlackClass .contactsubtitle {
    color: #fff !important;
}

.contactBlackClass .logoofourcompany {
    fill: white !important;
}
.contactBlackClass *::selection {
    background-color: #fff !important;
    color: #000 !important;
}
@media (max-width: 1399.98px) {
    .contacttitle {
        font-size: 45px !important;
        line-height: 55px;
    }

    .contactheadingtitle {
        font-size: 22px;
    }
}

@media screen and (max-width: 600px) {
    .svgondesktop {
        display: none;
    }
}

@media screen and (min-width: 600px) {
    .svgondesktop {
        display: inherit;
    }
}

@media (max-width: 575.98px) {
    .contacttitle {
        padding: 0px 75px !important;
    }

    .contactsubtitle {
        padding: 15px 60px !important;
    }
}

@media (max-width: 498.98px) {
    .contacttitle{
        font-size: 42px !important;
    }
}

@media (max-width: 475.98px) {
    .contactsubtitle {
        padding: 15px 15px !important;
    }
    .contacttitle {
        font-size: 32px !important;
        line-height: 47px !important; 
    }
}

@media screen and (max-width: 390px) {
    .contactus {
        padding: 100px 20px;
    }

    .maintitleofcategory {
        text-align: initial;
    }

    #contactusslider {
        height: 300px;
    }

    #carouselExampleInterval {
        padding: 50px 0px;
    }

    .svgonmobile {
        display: inherit;
    }

    .svgondesktop {
        display: none;
    }
}

@media screen and (max-width: 389.98px) {
    .contacttitle{
        font-size: 30px !important;
    }
}

@media screen and (max-width: 389.98px) {
    .contacttitle{
        font-size: 28px !important;
    }
}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 900px) {}

@media screen and (min-width: 1024px) {
    .svgonmobile {
        display: none;
    }

    .svgondesktop {
        display: inherit;
    }

    label.form-label {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;

        color: #3F4852;
    }

    .contactus input {
        background: #FFFFFF;
        border: 1px solid #E7EEF4;
        border-radius: 0px;
        width: 600px;
        text-align: start;
        padding: 10px 0px;
    }

    .option:hover {
        background-color: rgba(168, 14, 1, 0.05);
    }

    .contactus input::placeholder {
        padding-left: 1rem;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #94A2AB;
    }

    .contact label {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;

        color: #3F4852;

        padding: 0px 10px;
    }

    .contactusmaincomponent {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 100px 0px;
    }

    .maintitleofcategory {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #0F597E;

    }

    #buttonfinishh {
        background: #A80E01;
        border-radius: 0px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        padding: 10px 70px !important;
        color: #FFFFFF;
        border-color: #A80E01 !important;

    }

    #buttonfinishh:hover {
        color: #A80E01 !important;
        background: white;
        border-color: #A80E01 !important;
    }
}

textarea
{
   resize: none;
}