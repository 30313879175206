.viewAllBtn {
  background-color: #fff;
  color: #0a0a0a;
  font-weight: 700;
  padding: 15px 40px;
  border-radius: 0px;
  display: block;
  max-width: 200px;
  margin: auto;
}
.viewAllBtn:hover {
  color: #0a0a0a !important;
}
/* .viewAllBtn:hover {
  color: #0d6efd !important;
} */
.container {
  width: 350px; /* or whatever width you want */
  overflow: hidden; /* This will hide the slides that are not currently visible */
}
@media screen and (min-width: 1200px) {
  .maintitleofslider {
    font-size: 56px;
  }
  .slide1 {
    width: 495px;
  }
}
@media screen and (max-width: 1200px) {
  .maintitleofslider {
    font-size: 40px;
  }
  .titleofgridd {
    font-size: 30px !important;
  }
  .slide1 {
    width: 450px;
  }
}
.maintitleofslider {
  font-family: 'Bely';
  color: #000;
  font-weight: 400;
  line-height: 67px;
  letter-spacing: -0.9599999785423279px;
  text-align: left;
}
.buttononslider {
  background-color: #CECECE33;
  border: none;
  box-shadow: none;
}
.slider1 {
  display: flex; /* Makes children line up horizontally */
  width: calc(550px * 12); /* width of one slide times number of slides */
  height: 500px;
}

.slide1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
  background-position-y: center;
  background-repeat: no-repeat;
  padding-left: 1rem;
  height: 100%;
  padding-top: 1rem;
  background-size: contain;
}
.slide-content {
  z-index: 2; /* ensures content is above the image */
  color: black;
  text-align: center;
  position: relative; /* centers the title and subtitle in the slide */
}

.titleeee {
  font-family: 'Bely';
  font-size: 39px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
}
.subtitleee {
  font-family: Cabinet Grotesk;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
.slide1 img {
  max-width: 100%;
}

.hover-this-transform {
  transition: all 0.3s ease;
}

.portfolioCursor {
  pointer-events: none;
  position: fixed;
  padding: 0.5rem;
  background-color: #fff;
  mix-blend-mode: difference;
  transition: transform 0.3s ease;
  z-index: 13000;
  visibility: hidden;
}

.hover-this:hover ~ .portfolioCursor {
  transform: translate(-50%, -50%) scale(8);
}
/* .showCustomCursor {
  cursor: none;
} */

.showCustomCursor:hover  .portfolioCursor {
  visibility: visible;
}

.showCustomCursor span {
  color: #000;
}
/* body {
  cursor: none;
} */

@media (max-width: 991px) {
  .galleryofportfolio {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
  }
}

@media (max-width: 785px) {
  .galleryofportfolio {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
  }
  .maintitleoflandport {
    font-size: 50px !important;
    font-family: 'Bely' !important;
    color: white;
    line-height: 134%;
  }
}

@media screen and (max-width: 500px) {
  .galleryofportfolio {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    column-count: 1 !important;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  .slider1 {
    display: flex; /* Makes children line up horizontally */
    width: 300px !important; /* width of one slide times number of slides */
    height: 500px;
  }
  
  .viewAllBtn {
    width: 100%;
  }
}

.galleryofportfolio {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 42px;
}

.titleoflandport {
  font-family: 'Bely' !important;
  font-size: 25px;
  font-weight: 400;
  color: white;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitleoflandport {
  font-family: 'Cabinet Grotesk';
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.maintitleoflandport {
  font-size: 34px !important;
  font-family: 'Bely' !important;
  color: white;
}
