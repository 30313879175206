@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');
@font-face {
  font-family: 'Bely';
  src: url('./fonts/Bely.ttf');
}
/* body {
  font-family: 'Raleway' !important;
}

span {
  font-family: 'Raleway' !important;
}

p {
  font-family: 'Raleway' !important;
}

a {
  font-family: 'Raleway' !important;
} */

.fontofbuttonss {
  font-family: Helvetica;
  font-size: 15px !important;
  font-weight: 700 !important;
  border-radius: 0px !important;
  justify-content: center ;
  line-height: 29px;
  letter-spacing: 0.23999999463558197px;
  text-align: center;
  text-transform: uppercase;
}

.App {
  text-align: center;
  /* font-family: 'Raleway' !important; */
  /* overflow-x: hidden; */
}

html,
body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 900px) {
  .generaltitle {
    font-size: 45px;
    font-family: Bely !important;
  }
  .subgeneraltext {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #000000;
  }
}
@media screen and (min-width: 900px) {
  .generaltitle {
    font-family: 'Bely' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 135.4%;
    text-align: center;
    letter-spacing: -0.02em;

    color: #000000;
  }
  .subgeneraltext {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    text-align: center;

    color: #2e2e32;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
