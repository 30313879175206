.landingcontactmaindiv {
  /* background-color: #fff; */
  color: #000;
}

.thisiscontacttitle {
  font-weight: 400;
  font-size: 55px;
  line-height: 63px;
  color: #000;
  font-family: 'Bely' !important;
}

.thisissubcontacttitle {
  font-family: 'Bely' !important;
  font-weight: 400;
  font-size: 26px;
}

.thisiscontactsubtitle {
  font-size: 18px;
  font-family: 'Cabinet Grotesk' !important;
  font-weight: 300;
  line-height: 30px;
  color: #000;
}
.contacuswhite{
color: black !important;
}
.thisiscontactmaindiv {
  background: #000;
  border-radius: 0px;
  height: 605px;
}

.contactustitle {
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  color: #fff;
  font-family: 'Bely' !important;
}

.buttonhaha {
  font-family: 'Bely' !important;
  font-size: 24px;
}

.formcontact {
  border: 1px solid #4f4f4f !important;
  border-radius: 0px !important;
}

.blackContactUs .landingcontactmaindiv {
  background-color: #000;
  color: #fff;
  padding-block: 5rem;
}

.blackContactUs .thisiscontactmaindiv {
  background-color: #fff;

}

.blackContactUs .contactustitle {
  color: #000;
}

.blackContactUs .thisiscontacttitle {
  color: #fff;
}

.blackContactUs .thisissubcontacttitle{
  color: #fff;
}
.blackContactUs .thisissubcontacttitle{
  color: #fff;
}

.blackContactUs .thisiscontactsubtitle {
  color: #fff;
}

.blackContactUs .thisiscontacttitle::selection {
  background-color: #fff !important;
  color: #000000 !important;
}

.blackContactUs .thisissubcontacttitle::selection {
  background-color: #fff !important;
  color: #000000 !important;
}
.blackContactUs .thisiscontactsubtitle::selection {
  background-color: #fff !important;
  color: #000000 !important;
}

.blackContactUs .formcontact {
  background-color: #fff !important;
  border-color: #9E9E9E !important;
  color: #000000 !important;
}
.blackContactUs .form-control:focus {
  background-color: white !important;
    color: #000 !important;
}
.blackContactUs .buttonhaha {
  background-color: #000 !important;
  color: #fff !important;
}

@media (max-width: 1399.98px) {
  

  .digitalheadingstyle {
    width: 100% !important;
  }


  .thisiscontacttitle {
    font-size: 47px;
  }

  .thisissubcontacttitle {
    font-size: 24px;
  }
}

@media (max-width: 1199.98px) {
  

  .thisiscontacttitle {
    font-size: 47px;
  }

  .thisissubcontacttitle {
    font-size: 24px;
  }
}

@media (max-width: 991.98px) {
  
}

@media (max-width: 600px) {}

.digitalheadingstyle {
  width: 90%;
}

.containerofspace {
  padding-top: 8rem;
}


@media (max-width: 768px) {
  .containerofspace {
    padding-top: 6rem;
  }

  .thisiscontacttitle {
    line-height: 58px;
  }
}

@media (max-width: 601.98px) {
  .containerofspace {
    padding-top: 3rem;
  }
}

@media (max-width: 576px) {
  .thisiscontacttitle {
    line-height: 46px;
    font-size: 38px !important;
  }
  .thisissubcontacttitle {
    font-size: 18px;
  }
  .contactustitle {
    font-size: 25px;
  }
}