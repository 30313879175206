.titledetilport {
  font-family: 'Bely';
  font-size: 75px;
  font-weight: 400;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitledetailport {
  font-family: Cabinet Grotesk;
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0.06em;
  text-align: left;
}
.blackbox {
  background-color: black;
}

.titleofproblem {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.03em;
  text-align: center;
  color: white;
}
.titleofproblem::selection {
  color: black;
}
.solutiontitle::selection {
  color: black;
}
.solutiontitle {
  font-family: Poppins;
  font-size: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
.bulletpointbusiness {
  font-family: 'Bely';
  font-size: 35px;
  font-weight: 400;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
}
.linesofbussiness {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.imageboxbackground1 {
  background-image: url("./image.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  margin-left: -10px;
  margin-top: -10px;
  height: 22px;
  width: 22px;
}
.imageboxbackground2 {
  background-image: url("./image.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -10px;
  margin-top: -10px;
  height: 22px;
  width: 22px;
}
.imageboxbackground3 {
  background-image: url("./image.svg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -10px;
  margin-bottom: -10px;
  height: 22px;
  width: 22px;
}
.imageboxbackground4 {
  background-image: url("./image.svg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -10px;
  margin-bottom: -10px;
  height: 22px;
  width: 22px;
}

.borderofbox {
  border: 1px solid rgba(255, 255, 255, 0.6);
}
