.subtitleofwhy {
    padding: 1px 10px !important;
}

.borderus {
    border: 1px solid #000 !important;
}

.border-top {
    border-top: 2px solid #000 !important;
}

.titleofputting {
    font-family: 'Bely' !important;
    font-size: 40px;
    line-height: 45px;
    color: #000;
}

.subtitleofputting {
    font-family: 'Bely' !important;
    font-size: 40px;
    background-color: black;
    color: white;
    padding: 0px 8px !important;
}

@media (max-width: 1199.98px) { 
    
 }

@media (max-width: 991.98px) {
    .titleofputting {
    }
}

@media (max-width: 767.98px) { 
    .titleofputting {
        font-size: 32px;
    }
    .subtitleofputting{
        font-size: 32px;
    }

}


@media (max-width: 575.98px) {
    .titleofwhy {
        font-size: 30px
    }

    .subtitleofwhy {
        font-size: 20px
    }
    .titleofputting {
        font-size: 30px;
    }
    .subtitleofputting{
        font-size: 30px;
    }
}