@media (max-width: 2400.98px) {
  .dCQPbG {
    font-size: 25px;
  }

  .bOKNkR {
    font-size: 25px;
  }

  .vdkON {
    font-size: 25px;
  }

}
.fontfamilybely {
  font-family: Bely  !important;
  font-weight: 300 !important;
}





@media (min-width: 992.98px) {
  .gapoffooter {
    margin-left:  3rem;
    margin-right: 3rem;
  }
}
@media (min-width: 600.98px) {
  .displaythisondesktop {
    display: inherit;
  }
  .displaythisonmoblie {
    display: none;
  }
}
@media (max-width: 600.98px) {
  .displaythisondesktop {
    display: none;
  }
  .displaythisonmoblie {
    display: inherit;
  }
  .footerdiv1 {
    align-items: center;
    display: grid !important;
    justify-items: flex-start;
    grid-template-columns: auto auto auto;
  }

  .box1offooter {
    grid-column-start: 1;
    grid-column-end: 2;
  }





  .box3offooter {
    grid-column-start: 4;
    grid-column-end: 2;
  }

  .box4offooter {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .box5offooter {
    grid-column-start: 4;
    grid-column-end: 2;
  }

  .dCQPbG {
    font-size: 20px;
  }

  .bOKNkR {
    font-size: 15px;

  }

  .vdkON {
    font-size: 15px;

  }
}