@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

.maintittleee {
  text-align: center;
  font-weight: 400;
  padding-top: 2rem;
  font-family: 'Bely' !important;
  font-size: 55px !important;
  line-height: 55px;
  color: #000;
}

.teamMemberInfo {
  display: inherit;
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  z-index: 100;
  text-align: center;
  letter-spacing: 0.18px;
  
  color: #0a0a0a;
  padding: 6px 14px;
  top: 10px;
  right: 10px;
}

.teamMemberInfo2 {
  position: absolute;
    display: inherit;
    padding-block: 5px;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
@media screen and (min-width: 300px) {
  .displaythis {
    display: none;
  }

  .displaythis1 {
    display: none;
  }

  .Figure:hover .displaythis {
    display: inherit;
    position: absolute;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    z-index: 100;
    text-align: center;
    letter-spacing: 0.18px;
    
    color: #0a0a0a;
    padding: 6px 14px;
    top: 10px;
    right: 10px;
  }

  .theorderof2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Figure:hover .displaythis1 {
    position: absolute;
    display: inherit;
    padding: 5px;
    bottom: 0;
    left: 52%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .theorderof2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nameofteammember {
    font-style: normal;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.18px;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }

  .positionofteammember {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.18px;
    font-family: 'Poppins', sans-serif !important;
    color: #ffffff;
  }

  .imagehover {
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  .imagehover:hover {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 100%);
  }
}

@media screen and (max-width: 575px) {
  .sizeindifferentscreenss {
    /* height: 500px; */
    width: 100%;
  }

  .maintittleee {
    font-size: 36px !important;
  }
}

@media screen and (min-width: 768px) {
  .sizeindifferentscreenss {
    /* height: 600px !important; */
    width: 100%;
  }

  .nameofteammember {
    font-size: 18px;
  }

  .positionofteammember {
    font-size: 18px;
  }
}

.imagehover {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.imagehover:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.imagehover:hover .displaythis {
  display: flex;
  position: absolute;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: none;
  align-items: center;
  text-align: center;
  letter-spacing: 0.18px;
  
  color: #0a0a0a;
  padding: 5px;
  margin-top: 15px;
  z-index: 100;
  right: 0;
  right: 20%;
  justify-content: flex-end;
}

@media screen and (min-width: 2000px) {
  .maintittleee {
    font-size: 60px !important;
  }
}


@media (max-width: 475.98px) {
  .maintittleee {
    font-size: 36px !important;
    line-height: 41px;
  }

}

.containerofspace{
  padding-top: 8rem;
}


@media (max-width: 768px) { 
  .containerofspace{
      padding-top: 6rem;
  }
  /* .bottompaddingg {
    padding-bottom: 8rem;
  } */
}

@media (max-width: 601.98px) { 
  .containerofspace{
      padding-top: 3rem;
  }

}