.titleofvalue {
    color: #000;
    font-size: 55px;
    font-family: 'Bely' !important;
    line-height: 55px;
    font-weight: 400;
    color: #000;
}

.subtitleofvalue {
    font-family: 'Cabinet Grotesk' !important;
    line-height: 25px;
    font-weight: 300;
    font-size: 18px;
    color: #000;
}

.numberofvalues {
    font-family: 'Bely' !important;
    font-weight: 400;
    font-size: 35px;
    color: #000;
}

.titleofvalues {
    font-family: 'Cabinet Grotesk' !important;
    line-height: 25px;
    font-weight: 300;
    font-size: 30px;
}

.subtitleofvalues {
    font-family: 'Cabinet Grotesk' !important;
    line-height: 25px;
    font-weight: 300;
    font-size: 18px;
}

.valuetext {
    padding: 2px 152px;
}

@media (max-width: 991.98px) {
    .titleofvalue {
        font-size: 40px;
        line-height: 47px;
    }

    .valuetext {
        padding: 2px 0px !important;
    }
}

@media (max-width: 767.98px) {
    .titleofvalue {
        font-size: 35px;
        line-height: 47px;
    }
    .buildingsection {
        padding: 0px 50px;
    }
    .valuetext {
        padding: 0px 65px;
    }
}
@media (max-width: 600.98px) {
    .buildingsection {
        padding: 0px 0px;
    }
}
@media (max-width: 575.98px) {
    .titleofvalue {
        font-size: 36px !important;
        line-height: 41px !important;
    }
    
    .buildingsection {
        padding: 0px 0px;
    }

    .subtitleofvalue {
        font-size: 16px;
    }

    .subtitleofvalues {
        font-size: 16px;

    }
}

@media (max-width: 559.98px) {
    br {
        display: none;
    }

    .valuetext {
        padding: 0px;
    }
}


@media (max-width: 400.98px) {
    .titleofvalue {
        font-size: 32px !important;
        line-height: 43px !important;
    }
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {}

.swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border-radius: 1px !important;
}



.swiper-pagination-bullets .feature-pagination{
    left: 35%;
}