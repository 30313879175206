.body {
  /* width: 100vw; */
  /* height: 100vh; */
  /* background: linear-gradient(180deg, #0055ff 0%, rgb(0, 153, 255) 100%); */

  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.borderofthismenu {
  stroke: red;
  fill: red;
}
.no-scroll-y {
  overflow-y: hidden;
}

.bgsvgg {
  background-image: url("./ractangle.svg");
  position: fixed;
  top: 0%;
  right: 0%;
  background-repeat: no-repeat;
  height: 100%;
  width: 45px;
  background-position-y: calc(50% + 15px);
  z-index: 10000;
}
.wbgsvgg {
  background-image: url("./wractangle.svg");
  position: fixed;
  top: 0%;
  right: 0%;
  background-repeat: no-repeat;
  height: 100%;
  width: 45px;
  background-position-y: calc(50% + 15px);
  z-index: 10000;
}


.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 4000;
  background: #FDC93F;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); 
  
}
.background2 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 12003;
  background: #000;
}
.background3 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  z-index: 12003;
  background: #fff;
}

.button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 29px;
  right: 16%;
  z-index: 12000;
  /* transform: translateX(-50%); */
  width: 23px;
  border-radius: 50%;
  background: transparent;
}

.navbarOpenBtn {
  border: none !important;
  background-color: transparent !important;
  padding: 0px !important;
}
.openNavBarStyle .navbarOpenBtn {
  z-index: 12005;
  position: relative;
}
.zindexTransitionWrapper .zindexTransition {
  transition: z-index 0s 1s;
}
ul,
li {
  margin: 0;
  padding: 0;
}

.disableselection {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.titleofmenu {
  font-family: Bely !important;
  font-size: 100px;
  line-height: 63px;
  color: black !important
}
.subtitleofmenu {
  font-family: Cabinet Grotesk !important;
  font-size: 25px;
  font-weight: 300;
  color: black !important

}
ul {
  text-align: start;
  padding: 25px;
  display: flex;
  /* width: 500px; */
  height: 100%;
  z-index: 4001;
  justify-content: center;
  align-items: center;
  align-content: center;

}

@media screen and (max-width: 1600px) {
  .titleofmenu {
    font-size: 100px;
  }
}
@media screen and (max-width: 1400px) {
  .titleofmenu {
    font-size: 90px;
  }
}
@media screen and (max-width: 900px) {
  .titleofmenu {
    font-size: 75px;
  }
  .bgsvgg {
    background-image: none;
  }
  .wbgsvgg {
    background-image: none;
  }
 
}
@media screen and (max-width: 700px) {
  .titleofmenu {
    font-size: 45px;
  }
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;

  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
