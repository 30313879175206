.numberofcircle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 80px;
    line-height: 94px;
    /* identical to box height */
    color: #FFFFFF;
}

.textofcircle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    /* identical to box height */


    color: #FFFFFF;
}

.circleofabout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ourvaluesss {

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #2E2E32;
}

.ourhistory {
    font-family: 'Raleway';
    color: #2E2E32;
    font-style: normal;
    font-weight: 800;
    font-size: 70px;
    line-height: 82px;
    text-align: start;
    letter-spacing: -0.02em;
}

.ourhistorycontent {
    font-family: 'Raleway';
    font-style: normal;
    /* font-weight: 500; */
    /* font-size: 18px; */
    line-height: 163.4%;
    letter-spacing: 0.06em;
    text-align: initial;
    padding-top: 30px;
    color: #000000;
}

.testemonialtext {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #000000;
}

.testemonialtext p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    text-align: center;

    color: #000000;
}

.nameoftestemonails {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;

    color: #000000;
    padding-top: 50px;
}

.titleoftestemonials {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    padding: 60px 0px;
    color: #000000;
}

.meetuscontainer {
    background: #756CD9;
}

.titleofmeetus {

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #ffffff;
    padding: 20px 0px 20px 0px;
}




@media screen and (min-width: 2570px) {
    .containermeetus {
        max-width: 1600px;
        display: flex;
        justify-content: center;
    }

    .meetuscontainer {
        display: flex;
        justify-content: center;

    }


    .testemonials {
        background: url('https://res.cloudinary.com/dr5bzig6k/image/upload/v1665412892/About/sskoo_tg5htb.png');
        background-repeat: no-repeat;
        background-position: 89% 8%;

    }

    .circleofabout {
        width: 440px;
        padding: 40px 0px
    }

    .testemonialtext {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        justify-content: center;
        align-items: center;

        display: flex;
        color: #000000;
    }

    .titleofworkuas {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;

        color: #FFFFFF;
    }

    .nameofworkus {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 29px;
        padding: 0px;
        color: #FFFFFF;
    }
}
@media screen and (max-width: 2570px) {
    .containermeetus {
        max-width: 1600px;
        display: flex;
        justify-content: center;
    }

    .meetuscontainer {
        display: flex;
        justify-content: center;

    }


    .testemonials {
        background: url('https://res.cloudinary.com/dr5bzig6k/image/upload/v1665412892/About/sskoo_tg5htb.png');
        background-repeat: no-repeat;
        background-position: 89% 8%;

    }

    .circleofabout {
        width: 440px;
        padding: 40px 0px
    }

    .testemonialtext {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        justify-content: center;
        align-items: center;

        display: flex;
        color: #000000;
    }

    .titleofworkuas {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;

        color: #FFFFFF;
    }

    .nameofworkus {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 29px;
        padding: 0px;
        color: #FFFFFF;
    }
}


@media screen and (max-width: 1520px) {
    .containermeetus {
        max-width: 1600px;
        display: flex;
        justify-content: center;
    }

    .meetuscontainer {
        display: flex;
        justify-content: center;

    }


    .testemonials {
        background: url('https://res.cloudinary.com/dr5bzig6k/image/upload/v1665412892/About/sskoo_tg5htb.png');
        background-repeat: no-repeat;
        background-position: 89% 8%;

    }

    .circleofabout {
        width: 440px;
        padding: 40px 0px
    }

    .testemonialtext {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #000000;
    }
}

@media screen and (min-width: 1220px) {
    .value1 {
        justify-content: flex-end;
    }

    .value2 {
        justify-content: center;
    }

    .value3 {
        justify-content: start;

    }
}

@media screen and (max-width: 1220px) {
    .value1 {
        justify-content: center;
    }

    .value2 {
        justify-content: center;
    }

    .value3 {
        justify-content: center;

    }

    .circleofabout {
        width: 345px;
        padding: 40px 0px;
    }

    .desktopphotos {
        height: 140px
    }
}

@media screen and (max-width: 1020px) {
    .circleofabout {
        width: 310px;
        padding: 10px 40px;
    }

    .numberofcircle {
        font-size: 50px;
    }

    .textofcircle {
        font-size: 25px;
    }

    .rreshtiihistoris {
        padding: 0px 0px;
    }
}


@media screen and (max-width: 600px) {
    .rreshtiihistoris img {
        width: 346px;
        height: 344px;
    }

    .ourhistorycontent {
        font-family: 'Raleway';
        font-style: normal;
        /* font-weight: 500; */
        color: #000000;
        /* font-size: 20px; */
        line-height: 24px;
    }

    .ourhistory {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        text-align: left;

        color: #000000;

    }

    .ourvaluesss {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        text-align: center;

        color: #000000;
    }

    .circleofabout {
        width: 240px;
        height: 247px;
        padding: 0px;
        margin: 0px;
    }

    .numberofcircle {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 26px;
        /* identical to box height */


        color: #FFFFFF;
    }

    .textofcircle {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        /* identical to box height */

        text-align: center;

        color: #FFFFFF;

    }

    .containermeetus img {
        width: 160px;
        height: 160px;
    }

    .titleofmeetus {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 35px;
        text-align: center;

        color: #FFFFFF;
    }

    .rreshtiourvalues {

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }


}
@media screen and (max-width: 600px) {
    .desktopphotos {
        height: 100px
    }
}


@media screen and (min-width: 600px) {
    .rreshtiihistoris {
        padding: 1px;
        margin: 0px 40px;
    }


    .numberofcircle {
        margin: 0px !important;

    }

    .textofcircle {
        margin: 0px !important;
    }
}