#lightBox {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 0;
    z-index: 2000
}

.cardofcircle {
    color: white;
    z-index: 300;
}

.circletest {
    color: white
}

.photoofcard {
    max-width: 300px;
    align-self: center;
    justify-self: center;
}

.listoffirstcard {
    color: rgba(255, 255, 255, .7);
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0;
}

.titleoffirstcard {
    font-size: 40px;
    color: white;
    padding: 30px 0px 10px 0px;
    font-weight: 700;
    align-self: start;
    word-spacing: 1;
}

.cardofcircle {
    margin-bottom: 40px;
    padding: 0;
    border-radius: 0px;
    background-color: hsla(0, 0%, 100%, 0);
    border: 2px solid hsla(0, 0%, 100%, .1);
    outline-offset: 0px;
    outline: hsla(0, 0%, 100%, .1) 2px;
    mix-blend-mode: normal;
    padding: 48px;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerofcardsofcircle {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.stickydiv {
    position: sticky;
    top: 0;
    z-index: 50;
    display: flex;
    overflow: hidden;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #FDC93F;
}

.rreshtijoneee {
    z-index: 100;
    color: white;
    padding-right: 1201px;
    padding-bottom: 500px;
    position: absolute;
    line-height: 120px;

}

.titleofcardsss {
    position: absolute;
}

.cards {
    display: flex;
    flex-direction: column;
}

.sticky {
    position: sticky;
    display: flex;
}

.circlesection {
    position: sticky;
    top: 0;
}
.circlesection *::selection {
    background-color: #fff !important;
    color: #000000 !important;
}
.outercircle {
    /* will-change: width, height; */
    /* padding: calc(4rem - 20px); */
    /* width: calc(100% - 10px); */
    background-color: white;
    /* border-radius: 100%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.innercircle {
    width: 90%;
    height: 90%;
    margin: auto;
    /* border-radius: 100%; */
    background-color: #000;
    /* will-change: transform; */
    /* transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
    /* transform-style: preserve-3d; */
}


.maintitleofservices {
    font-family: 'Bely' !important;
}

@media screen and (min-width: 2560.98px) {
    .cardofcircle {
        aspect-ratio: 1/1;
        max-width: 575px;
    }

    .maintitleofservices {
        position: absolute;
        font-size: 89px;
        font-weight: 800;
        text-align: start;
        transition: opacity 0.15s ease-in-out
    }

    .containerofcardsofcircle {
        padding-top: 1200px;
        padding-right: 300px;
        max-width: 1140px;
        display: flex;
        align-items: flex-end
    }

    #lightBox {
        min-height: 870vh;
    }
}

@media screen and (max-width: 2560.98px) {
    .cardofcircle {
        aspect-ratio: 1/1;
        max-width: 575px;
    }

    .maintitleofservices {
        position: absolute;
        font-size: 89px;
        font-weight: 800;
        text-align: start;
        transition: opacity 0.15s ease-in-out
    }

    .containerofcardsofcircle {
        padding-top: 1200px;
        padding-right: 300px;
        max-width: 1140px;
        display: flex;
        align-items: flex-end
    }

    #lightBox {
        min-height: 870vh;
    }
}

@media screen and (max-width: 1920px) {
    .containerofcardsofcircle {
        padding-top: 1200px;
        padding-right: 300px;
        max-width: 1140px;
        display: flex;
        align-items: flex-end
    }

    .mobiletitleofcircle {
        display: none;
    }

    .maintitleofservices {
        position: absolute;
        font-size: 89px;
        font-weight: 800;
        text-align: start;
        transition: opacity 0.15s ease-in-out
    }

    .titleofcardsss {
        display: inherit;
    }

    #lightBox {
        min-height: 1120vh;
    }

}

@media screen and (max-width: 1640px) {

    #lightBox {
        min-height: 1200vh;
    }
}

@media (max-width: 1440px) {

    #lightBox {
        min-height: 1250vh;
    }

}

@media (max-width: 1240px) {
    #lightBox {
        min-height: 1150vh;
    }

    .cardofcircle {
        max-width: 500px;
        aspect-ratio: unset;

    }

    .maintitleofservices {
        margin-left: 60px;
    }
    .maintitleofservices {
        padding-left: 132px;
        font-size: 75px;
        line-height: 1.3;
        letter-spacing: -0.02em;
    }
}
@media (max-width: 1199.98px) { 
    /* .innercircle{
        height: 100% !important;
    } */
 }
@media (max-width: 1024px) {
    .maintitleofservices {
        margin-left: 60px;
    }
    .cardofcircle {
        max-width: 450px;
    }
    #lightBox {
        min-height: 1350vh;
    }
}

@media (max-width: 900px) {
    .maintitleofservices {
        margin-left: 160px;
        line-height: 1.3;
        font-size: 50px;
    }
}

@media (max-width: 768px) {
    .maintitleofservices {
        padding-left: 432px;
        font-weight: 800;
        font-size: 45px;
        line-height: 53px;
        padding-top: 150px;

        letter-spacing: -0.02em;

    }
    .cardofcircle {
        max-width: 100%;
    }
    
    #lightBox {
        min-height: 1250vh;
    }

}

@media (min-width: 600px) {
    .photoofcard {
        max-width: 270px;
    }
    
}
@media (max-width: 600px) {
    .cardofcircle {

        max-width: 450px;
    }
    .photoofcard {
        max-width: 230px;
    }
    .containerofcardsofcircle {
        padding-left: 0px;
        align-items: center;
        padding-top: 750px;

    }

    .cardofcircle {
        padding: 18px;

    }

    #lightBox {
        min-height: 980vh;
    }



}

@media (max-width: 460px) {
    .cardofcircle {

        max-width: 350px;

    }

    #lightBox {
        min-height: 1100vh;
    }

}

