@media screen and (max-width: 995px) {
  .scrollonsmallscreens {
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
  .scrollonsmallscreens::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .scrollonsmallscreens::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .scrollonsmallscreens::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .scrollonsmallscreens::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } 
}
